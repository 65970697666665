<template>
  <v-row v-scroll="onScroll">
    <!-- header -->
    <mini-header :dense="true" title="Resumen del Documento" :breadcrumbs="breadcrumbs" :breadcrumbsItemDinamic="{ loading: $store.state.purchases.retrieveLoader, text: `${(activeDocument || {}).name } Nº ${instance.number}`}" :hiddenTab="true" :addPadding="true" :scroll="scroll">
      <template v-slot:main>
        <v-btn class="mr-2" @click="retrieve" outlined>
          <v-img contain :src="require(`@/assets/icon-update${$vuetify.theme.dark ? '-dark' : ''}.svg`)" />
        </v-btn>
        <v-btn class="mr-2" @click="modalEmail=true" outlined><v-icon left>mdi-eye</v-icon>Ver email</v-btn>
        <v-btn @click="downloadFile2('purchases', `${instance.type}_N${instance.number}`, 'rec-docs', 'xml', instance.id)" :disabled="$store.state.purchases.retrieveLoader" outlined><v-icon left>mdi-download</v-icon>Descargar documento</v-btn>
        <template v-if="instance?.status === 'APR'">
          <v-btn class="ml-2" @click="dialogSwap=true, dialogSwapType = 'accept'" outlined :ripple="false" :disabled="['APR'].includes(instance?.rec_status)">
            <v-icon left>mdi-file-document-check</v-icon>
            Aceptar
          </v-btn>
          <v-btn class="ml-2" @click="dialogSwap=true, dialogSwapType = 'reclaim'" color="blue-500" :ripple="false" :disabled="['REJ'].includes(instance?.rec_status)">
            <v-icon left>mdi-file-document-remove</v-icon>
            Rechazar
          </v-btn>
        </template>
      </template>
    </mini-header>
    <!-- end header -->
    <v-col class="px-0" style="z-index: 0;">
      <skeleton-detail v-if="$store.state.purchases.retrieveLoader" />
      <div class="d-flex" v-else>
        <!-- detail card -->
        <div class="mr-6 mt-n1" style="min-width: 400px;">
          <div style="position:fixed!important;">
            <v-img contain :width="400" :height="110" :src="require(`@/assets/backgrounds/background-document-detail.svg`)">
              <span class="px-4 pt-4 subtitle-2 white--text font-weight-bold d-inline-block text-truncate" style="max-width: 400px;">
                {{instance.type | nameDocumentsType}}
              </span>
              <v-row class="ml-2 mt-3" align="end" no-gutters>
                <v-col cols="4" class="py-0 px-2">
                  <span class="d-block body-1 white--text">Total</span>
                  <span class="subtitle-2 white--text">
                    <span class="font-weight-bold">{{instance.amount_total | currency(instance.type)}}
                      <span class="caption">{{instance.type | firstDocumentsTypeCurrency}}</span>
                    </span>
                  </span>
                </v-col>
                <v-col cols="5" class="py-0 px-2">
                  <span class="d-block body-1 white--text">Fecha</span>
                  <span class="subtitle-2 white--text">
                    <span class="font-weight-bold">{{instance.date | date}}</span>
                  </span>
                </v-col>
              </v-row>
            </v-img>
            <v-card class="pa-0 mt-4" width="400" transition="slide-y-transition" min-height="calc(100% - 278px)" flat style="position:fixed!important;">
              <v-toolbar color="transparent" flat height="46">
                <v-toolbar-title class="px-5 subtitle-2 grey-500--text font-weight-bold">Resumen del documento</v-toolbar-title>
              </v-toolbar>
              <v-divider />
              <div class="py-4 px-5">
                <v-list class="v-list-form-main transparent pa-0">
                  <v-list-item class="pa-0">
                    <v-list-item-title class="grey-500--text subtitle-2">Razón Social</v-list-item-title>
                      <v-list-item-subtitle class="body-1">{{(instance || {}).supplier_name}}</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item class="pa-0">
                      <v-list-item-title class="grey-500--text subtitle-2">{{$t('generals.RUT')}}</v-list-item-title>
                      <v-list-item-subtitle class="body-1">{{ (instance || {}).tax_id}}</v-list-item-subtitle>
                    </v-list-item>
                  <v-list-item class="pa-0">
                    <v-list-item-title class="grey-500--text subtitle-2">Tipo de documento</v-list-item-title>
                    <v-list-item-subtitle class="body-1">{{instance.type | nameDocumentsType}}</v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item class="pa-0" v-if="instance.status !== 'draft'">
                    <v-list-item-title class="grey-500--text subtitle-2">Serie - Correlativo</v-list-item-title>
                    <v-list-item-subtitle class="body-1">{{instance.number}}</v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item class="pa-0">
                    <v-list-item-title class="grey-500--text subtitle-2">ID</v-list-item-title>
                    <v-list-item-subtitle>
                      <v-tooltip color="grey-background" right transition="scale-transition" max-width="320">
                        <template v-slot:activator="{on}">
                          <v-btn class="grey-300--text" @click="toClipboard(instance.id)" text v-on="on" :ripple="false" style="justify-content: left;">{{instance.id}}</v-btn>
                        </template>
                        <span class="d-block px-3 py-2">Copiar</span>
                      </v-tooltip>
                    </v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item class="pa-0">
                    <v-list-item-title class="grey-500--text subtitle-2">Creado el</v-list-item-title>
                    <v-list-item-subtitle class="body-1">{{instance.created | dateTimeSecond}}</v-list-item-subtitle>
                  </v-list-item>
                </v-list>
              </div>
            </v-card>
          </div>
        </div>
        <!-- end summary -->

        <!-- panels -->
        <div class="pb-5" style="flex:1; min-width: 180px;">
          <!-- compliance -->
          <v-card flat class="mb-5">
            <v-toolbar color="transparent" flat height="46">
              <v-toolbar-title class="px-5 subtitle-2 grey-700--text font-weight-bold">
                <v-row no-gutters align="center" justify="space-between">
                  <v-col class="pa-0">
                    <span class="ml-n1 subtitle-2 grey-700--text font-weight-semibold"><v-icon class="mr-2 mt-n1" small color="grey-700">mdi-shield-check</v-icon>Cumplimiento
                    </span>
                  </v-col>
                </v-row>
              </v-toolbar-title>
            </v-toolbar>
            <v-divider />
            <v-card-text class="px-0 py-4">
              <v-skeleton-loader v-if="loadingSwap" class="rounded-md" type="list-item-three-line" />
              <v-list class="v-list-form-2-actions transparent pa-0" width="100%" v-else>
                <v-list-item class="px-5">
                  <v-list-item-title class="grey-500--text subtitle-2">Estado comercial</v-list-item-title>
                  <v-list-item-subtitle>
                    <chip-status :status="instance?.rec_status || 'PEN'" :detail="instance?.rec_status_info ? instance?.rec_status_info : null" :showDetail="true" type="purchases" :isTooltip="true" />
                  </v-list-item-subtitle>
                </v-list-item>
                <v-divider class="my-5" />
                <v-list-item class="px-5">
                  <v-list-item-title class="grey-500--text subtitle-2">Estado SUNAT</v-list-item-title>
                  <v-list-item-subtitle>
                    <chip-status :status="instance?.status" :showDetail="true" type="documents" :isTooltip="false" />
                  </v-list-item-subtitle>
                  <v-list-item-action class="ma-0" style="overflow: hidden">
                    <v-btn class="btnText" v-if="['FAI', 'SNT', 'CRE', 'ART', 'MRT'].includes(instance?.status)" @click="reprocessDoc" :disabled="disabledUpdate" text color="blue-500" :ripple="false">
                      <v-icon left color="blue-500">mdi-refresh</v-icon>
                      Actualizar estado
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item class="px-5" v-if="!['FAI', 'SNT', 'CRE', 'ART', 'MRT'].includes(instance?.status)">
                  <v-list-item-title class="grey-500--text subtitle-2">Fecha de validación</v-list-item-title>
                  <v-list-item-subtitle>
                    {{instance.validation_date | dateTimeSecond}}
                  </v-list-item-subtitle>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
          <!-- end compliance -->

          <!-- totals -->
          <v-expansion-panels v-model="panelTotals">
            <v-expansion-panel>
              <v-expansion-panel-header class="px-5 py-4">
                <v-row no-gutters align="center" justify="space-between">
                  <v-col class="pa-0">
                    <span class="ml-n1 subtitle-2 grey-700--text font-weight-semibold"><v-icon class="mr-2 mt-n1" small color="grey-700">mdi-currency-usd</v-icon>Detalle</span>
                  </v-col>
                  <v-col class="text-right mr-2 pa-0" v-if="panelTotals !== 0">
                    <span class="d-block grey-700--text body-1 font-weight-medium">{{instance.amount_total | currency(instance.type)}} {{instance.type | firstDocumentsTypeCurrency}}</span>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="px-0 pb-4">
                <v-list class="transparent pa-0">
                  <v-list-item class="px-5">
                    <v-list-item-title class="subtitle-2">Subtotal</v-list-item-title>
                    <v-list-item-subtitle class="text-right font-weight-bold">{{instance.amount_subtotal | currency(instance.type)}}</v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item class="px-5">
                    <v-list-item-title class="subtitle-2">Descuento</v-list-item-title>
                    <v-list-item-subtitle class="text-right grey-500--text font-weight-bold">
                      <template v-if="instance.amount_discount">
                        {{instance.amount_discount | number}}
                        <template v-if="instance.discount_scheme === '%'">{{instance.discount_scheme}}</template>
                        <template v-if="instance.discount_scheme === '$'">{{instance.type | firstDocumentsTypeCurrency}}</template>
                      </template>
                      <template v-else>-</template>
                    </v-list-item-subtitle>
                  </v-list-item>
                  <template v-if="(activeDocument || {}).has_taxes_detail">
                    <v-divider class="my-1" />
                    <v-list-item class="px-5">
                      <v-list-item-title class="subtitle-2">Exento</v-list-item-title>
                      <v-list-item-subtitle class="text-right grey-500--text font-weight-bold">{{instance.amount_exempt | currency(instance.type)}}</v-list-item-subtitle>
                    </v-list-item>
                    <template v-if="activeDocument.has_taxes">
                      <v-list-item class="px-5">
                        <v-list-item-title class="subtitle-2">Neto</v-list-item-title>
                        <v-list-item-subtitle class="text-right grey-500--text font-weight-bold">{{instance.amount_net | currency(instance.type)}}</v-list-item-subtitle>
                      </v-list-item>
                      <v-divider class="my-1" />
                      <v-list-item class="px-5">
                        <v-list-item-title class="subtitle-2">Impuestos ({{activeDocument.tax_percent}}%)</v-list-item-title>
                        <v-list-item-subtitle class="text-right grey-500--text font-weight-bold">{{instance.amount_tax | currency(instance.type)}}</v-list-item-subtitle>
                      </v-list-item>
                    </template>
                  </template>
                  <v-divider class="my-1" />
                  <v-list-item class="px-5">
                    <v-list-item-title class="body-1 grey-500--text font-weight-bold">Total documento</v-list-item-title>
                    <v-list-item-subtitle class="text-right body-1 grey-500--text font-weight-bold">{{instance.amount_total | currency(instance.type)}} {{instance.type | firstDocumentsTypeCurrency}}</v-list-item-subtitle>
                  </v-list-item>
                </v-list>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <!-- end totals -->

          <!-- pdf -->
          <v-expansion-panels class="mt-5" v-model="panelPDF">
            <v-expansion-panel>
              <v-expansion-panel-header class="px-5 py-4">
                <v-row no-gutters align="center">
                  <v-col class="pa-0">
                    <span class="subtitle-2 grey-700--text font-weight-semibold"><v-icon class="mr-2" small color="grey-700">mdi-text-box</v-icon>Visualizar PDF</span>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="px-0" style="min-height: 300px;">
                <render-pdf :basicRender="true" :documentType="((instance || {}).type || '') | nameDocumentsType" :number="instance.number" @close="modalPDF=false" :loaderRender="loaderRender" :renderUrl="renderUrl" :flat="true" :showToolbar="false" :showFormat="true"  />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <!-- end pdf -->

          <!-- related documents -->
          <v-expansion-panels class="mt-5" v-model="panelRelatedDocuments">
            <v-expansion-panel>
              <v-expansion-panel-header class="px-4 py-3">
                <v-row no-gutters align="center" justify="space-between">
                  <span class="subtitle-2 grey-700--text font-weight-bold d-flex align-center"><v-icon size="18" left color="grey-700">mdi-file-document-multiple</v-icon>Referencias</span>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="px-0">
                <v-divider />
                <v-empty-state class="mt-n2 pb-2" v-if="!references?.length" type="documents" id="documentos" customClass="mt-n5" :isFree="true" title="No hay información de referencias" :height="199" />
                <v-simple-table class="px-0 no-hover table-not-rounded" dense v-else>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left grey-700--text font-weight-medium" style="min-width: 180px;">Tipo de documento</th>
                        <th class="text-right grey-700--text font-weight-medium" style="min-width: 120px;">Serie-Correlativo</th>
                        <th class="text-right grey-700--text font-weight-medium" style="min-width: 70px;">Fecha del doc.</th>
                      </tr>
                      <v-divider style="position: absolute;  margin-top: -10px; min-width: 100%; margin-left: -1px" />
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in references"
                        :key="item.number"
                        style="cursor: pointer"
                        @click="$router.push({ name: 'PurchaseList', params: $route.params, query: { type: item.type, series: item.series, serial: item.serial, _name: instance.number } })"
                      >
                        <td class="body-2">
                          <span class="d-inline-block text-truncate" style="max-width: 99%;" v-if="item.type">{{item.type  | nameDocumentsType}}</span>
                          <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
                        </td>
                        <td class="text-right body-2">
                          <span>{{item.number}}</span>
                        </td>
                        <td class="text-right body-2">
                          <span v-if="item.date">{{item.date | date}}</span>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        <!-- end related documents -->

          <!-- comments --
          <v-expansion-panels class="mt-5" v-model="panelComments">
            <v-expansion-panel>
              <v-expansion-panel-header class="px-4 py-3">
                <v-row no-gutters align="center" justify="space-between">
                  <v-col>
                    <span class="subtitle-2 grey-700--text font-weight-bold"><v-icon small left color="grey-700">mdi-email</v-icon>Comentarios</span>
                  </v-col>
                  <v-col class="text-right">
                    <v-icon size="18" left :color="comments ? 'blue-500' : 'grey-700'">mdi-message-badge</v-icon>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="pa-0">
                <v-container>
                  <v-row class="px-5 py-5" align="start">
                    <img :src="require(`@/assets/user-${ $vuetify.theme.dark ? 'dark' : 'light'}.svg`)" :width="30" />
                    <v-col class="py-0 pr-0">
                      <v-textarea placeholder="Escribe un comentario..." auto-grow outlined flat hide-details />
                    </v-col>
                  </v-row>
                 <v-list class="pa-0" three-line v-if="comments">
                  <v-list-item>
                    <v-list-item-avatar>
                      <img :src="require(`@/assets/user-${ $vuetify.theme.dark ? 'dark' : 'light'}.svg`)" :width="30" />
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title class="subtitle-1 font-weight-bold grey-700--text">Juan Pérez</v-list-item-title>
                      <span class="d-block grey-700--text caption">Hace 3 horas</span>
                      <v-list-item-subtitle class="grey-700--text mt-1">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fames libero integer potenti at in nec. Urna turpis massa est ultrices faucibus diam et donec vitae.
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                 </v-list>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <!- end comments -->
        </div>
        <!-- end panels -->
      </div>
    </v-col>

    <!-- dialog swap update -->
    <v-dialog v-model="dialogSwap" width="410" persistent no-click-animation overlay-color="grey-500">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-5 subtitle-2 grey-700--text font-weight-semibold">{{ dialogSwapType !== 'reclaim' ? 'Aceptar' : 'Rechazar' }} documento</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialogSwap=false" icon small retain-focus-on-click><v-icon color="grey-500" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="px-5 py-4">
          <v-row class="pt-8" align="center" justify="center">
            <img :src="require(`@/assets/backgrounds/modal-purchase-null.svg`)" :width="168" />
          </v-row>
          <v-col class="pt-8 px-0 pb-0">
            <v-list class="pa-0 transparent" three-line>
              <v-list-item class="pa-0">
                <v-list-item-content>
                  <v-list-item-subtitle class="pt-2">
                    <v-text-field v-model="swapResponse.rec_status_info" outlined single-line dense hide-details placeholder="Descripción" />
                    <!-- <v-autocomplete v-model="swapResponse.status" :items="swapStatuses" item-text="label" item-value="value" single-line hide-details dense return-object outlined /> -->
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-2 px-5">
          <v-spacer/>
          <v-btn @click="dialogSwap=false" outlined color="grey-700">Cancelar</v-btn>
          <v-btn @click="updateSwap" :loading="loadingSwap" color="blue-500">{{ dialogSwapType !==
            'reclaim' ? 'Aceptar' : 'Rechazar' }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog swap update -->

    <!-- dialog send email new ui -->
    <v-dialog v-model="modalEmail" width="700" persistent no-click-animation overlay-color="grey-500" scrollable>
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar class="transparent" flat height="46">
            <v-toolbar-title class="px-5 subtitle-2 grey-500--text font-weight-semibold">Email</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="modalEmail=false" icon small retain-focus-on-click><v-icon color="grey-500" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-0" >
          <v-col class="pa-0">
            <v-card class="transparent rounded-0 pa-0" flat>
              <v-card-subtitle class="background pt-5 pb-3 px-5">
                <div class="d-flex align-center">
                  <span class="grey-500--text body-1" style="min-width: 120px;">De</span>
                  <v-text-field v-model="messages.recipient" outlined required single-line dense readonly persistent-hint hide-details />
                </div>
                <div class="d-flex align-center mt-3">
                  <span class="grey-500--text body-1" style="min-width: 120px;">Asunto</span>
                  <v-text-field v-model="messages.subject"  outlined required single-line dense readonly maxlength="60" persistent-hint hide-details />
                </div>
                <div class="d-flex align-center mt-4" v-if="messages.date">
                  <span class="grey-500--text body-1" style="min-width: 120px;">Fecha de envío</span>
                  <span class="grey-300--text body-1" style="min-width: 120px;">{{ messages.date | dateTimeSecond }}</span>
                </div>
                <v-col cols="12" class="pa-0 mt-5">
                  <span class="grey-500--text body-1 mr-5 mb-3 d-block">Archivos adjuntos</span>
                  <v-row class="ml-n1" no-gutters>
                    <v-col cols="5" v-for="file in messages.files" :key="file.index">
                      <v-card class="px-2 py-0 ma-1" @click="downloadFileEmail(file)" flat rounded="sm" :height="44" outlined>
                        <v-list-item class="ma-0 pa-0 d-flex align-center">
                          <v-list-item-icon class="mb-0 mt-2 ml-0 mr-2">
                            <v-icon color="grey-700" size="28">mdi-paperclip</v-icon>
                          </v-list-item-icon>
                          <v-list-item-subtitle>
                            <span class="caption grey-500--text d-inline-block text-truncate" :class="!file.size ? 'mt-2' : 'mt-1'" style="max-width: 220px;">{{ file.file_name }}</span>
                            <span class="mt-n4 d-block grey-300--text overline d-block" v-if="file.size">{{file.size }}</span>
                          </v-list-item-subtitle>
                        </v-list-item>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col>
                <v-btn class="mt-3" @click="downloadAllEmails" text color="blue-500"><v-icon left>mdi-download</v-icon>Descargar todos</v-btn>
              </v-card-subtitle>
              <v-divider />
              <v-col class="px-0 pt-5 ml-n1 pb-0">
                <v-textarea class="elevation-0" v-model="messages.text" readonly auto-grow solo flat hide-details />
              </v-col>
            </v-card>
          </v-col>
          <v-col>
          </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- end dialog -->

    <!-- dialog confirm swap update -->
    <v-dialog v-model="dialogConfirmSwap" width="838" persistent no-click-animation overlay-color="grey-500">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-5 subtitle-2 grey-300--text--grey-500 font-weight-semibold">{{ dialogSwapType !== 'reclaim' ? 'Aceptar' : 'Rechazar' }} </v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialogConfirmSwap=false" icon small retain-focus-on-click><v-icon color="grey-300--text" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-4 px-5 grey-700--text">
          <v-row class="pt-2" align="center">
            <v-col cols="3">
              <img :src="require(`@/assets/backgrounds/modal-document-null.svg`)" :width="188" />
            </v-col>
            <v-col class="pr-4 body-1 pl-6">
              Se registrará el evento <span class="font-weight-bold text-lowercase">{{swapResponse.status.label || swapStatuses[0].label }}</span> en el registro de compras del {{$t('generals.SII')}}, y se le enviará, una notificación al proveedor informando la respuesta del documento.

              <span class="d-block body-1 pt-3">Al confirmar esta acción, no habrá vuelta atrás.</span>
              <span class="d-block body-1 mt-4 mb-3">Escribe <span class="font-weight-bold">“confirmar”</span> para continuar.</span>
              <v-text-field v-model="confirm" outlined single-line maxlength="9" dense hide-details />

              <!-- alert -->
              <v-alert class="inf-alert-blue blue-500--text rounded-md mt-4" :height="50" dense text>
                <div class="d-flex pa-0 my-1 pt-1">
                  <v-icon color="blue-500" size="22">mdi-information-outline</v-icon>
                  <div class="ml-4 text-description">
                    <span class="body-2" style="line-height: 14px;">Esta acción
                      <span class="font-weight-bold">podría tardar varios minutos</span> en completarse.
                    </span>
                  </div>
                </div>
              </v-alert>
              <!-- end alert -->
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-2 px-5">
          <v-spacer/>
          <v-btn @click="dialogConfirmSwap=false" outlined>Cancelar</v-btn>
          <v-btn color="blue-500" @click="dialogSwap=false, updateSwap()" :loading="$store.state.irs.createLoader" :disabled="confirm.toLowerCase() !== 'confirmar' ">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog confirm swap update -->
  </v-row>
</template>

<script>
  import { mapState } from 'vuex'
  import { saveAs } from 'file-saver'
  import VEmptyState from '@/components/commons/VEmptyState'
  import MiniHeader from '@/components/commons/MiniHeader'
  import ChipStatus from '@/components/commons/ChipStatus'
  import VModal from '@/components/commons/VPanel'
  import SkeletonDetail from '@/components/commons/skeletonLoader/SkeletonDetail'
  import RenderPdf from '@/components/files/RenderPdf'
  import DocumentsList from '@/modules/documents/components/DocumentsList'
  import GenericViewMixin from '@/mixins/GenericViewMixin'
  import RenderViewMixin from '@/mixins/RenderViewMixin'
  import TaxAgencyViewMixin from '@/mixins/TaxAgencyViewMixin'
  import DocumentTypes from '@/collections/documentTypes'
  import PermissionDenied from '@/modules/http/views/PermissionDenied'

  export default {
    mixins: [
      GenericViewMixin,
      RenderViewMixin,
      TaxAgencyViewMixin
    ],
   components: {
      VEmptyState,
      ChipStatus,
      MiniHeader,
      VModal,
      SkeletonDetail,
      RenderPdf,
      DocumentsList,
      PermissionDenied
    },
    data: () => ({
      references: [],
      dialogSwapType: '',
      key: 0,
      modalEmail: false,
      emailsList: [],
      disabledUpdate: false,
      formatPDF: 1,
      scroll: 0,
      confirm: '',
      comments: '',
      dialogSwap: false,
      dialogConfirmSwap: false,
      loading: false,
      loadingSwap: false,
      // offsetTop: 0,
      panelIrsSii: [],
      panelComments: false,
      panelEmail: false,
      panelPDF: false,
      panelRelatedDocuments: false,
      panelTotals: 0,
      swap: {},
      swapResponse: {
        status: 'ERM',
        rec_status_info: ''
      },
      swapStatuses: [
        { value: 'ERM', label: 'Otorga recibo de mercaderías o servicios' },
        { value: 'ACD', label: 'Acepta contenido del documento' },
        { value: 'RCD', label: 'Reclamo al contenido del documento' },
        { value: 'RFP', label: 'Reclamo por falta parcial de mercaderías' },
        { value: 'RFT', label: 'Reclamo por falta total de mercaderías' }
      ],
      breadcrumbs: {
        main: 'Recepción',
        children: [
          {
            text: 'Documentos',
            route: 'PurchaseList'
          }
        ]
      },
      messages: {
        text: '',
        recipient: '',
        subject: '',
        type: ['pdf', 'xml'],
        files: [],
        date: null
      }
    }),
    computed: {
      ...mapState({
        instance: state => state.purchases.recdocsDetail
      }),
      activeDocument () {
        return DocumentTypes.find(d => d.id === this.instance.type)
      }
    },
    watch: {
      panelPDF (val) {
        this.modalRender = val
        if (val === 0) {
          this.$vuetify.goTo(0, { duration: '0', easing: 'linear' })
          if (!this.renderUrl) this.renderDocument2('documents', 'rec-docs', 'pdf', this.$route.params.id)
        }
        this.formatPDF = 1
      },
      formatPDF: {
        handler (val) {
          if (val === 1) this.renderDocument('documents')
          else {
            this.renderDocument('purchases', 'purchases', 'pdf', this.instance.id, { thermal_format: val})
          }
        },
        inmediate: true,
        deep: true
      },
      modalEmail (val) {
        if (val) this.getEmail()
        else {
        this.messages = {
          text: '',
          recipient: '',
          subject: '',
          type: ['pdf', 'xml'],
          files: [],
          date: null
          }
        }
      }
    },
  created () {
      this.retrieve()
    },
    methods: {
      downloadFileEmail (file = {}) {
        this.$store.dispatch(`accounts/RETRIEVE`, {
          resource: `rec-emails/${this.instance.email_id}/attachments/${file.index}`,
          loader: false,
          responseType: 'arraybuffer'
        })
        .then((response) => {
          saveAs(new Blob([response.data], {type: `application/${file.mime_type}`}), file.file_name)
        })
      },
      downloadAllEmails () {
        this.$store.dispatch('accounts/RETRIEVE', {
          resource: `rec-emails/${this.instance.email_id}/attachments`,
          responseType: 'arraybuffer'
        })
        .then(async (response) => {
          const blob = await new Blob([response.data], {type: "application/zip"})
          saveAs(blob, response.headers['content-disposition'].match(/"([^"]*)"/g)[0].replace(/"/g, ''))
          this.$dialog.message.info('La descarga se ha realizado con éxito.')
        })
        .catch(() => {
          this.$dialog.message.error('Ha ocurrido un error en el proceso de descarga')
        })
      },
      remove (item) {
        const index = this.messages.recipient.indexOf(item)
        if (index >= 0) {
          this.messages.recipient.splice(index, 1)
        }
      },
      getEmail () {
        this.loadingDetail = true
        this.$store.dispatch('documents/RETRIEVE', {
          resource: 'rec-emails',
          id: this.instance.email_id
        })
        .then((response) => {
          this.messages.recipient = response.data.from
          this.messages.subject = response.data.subject
          this.messages.files = response.data.attachments
          this.messages.text += response.data.body
          this.messages.date = response.data.sent_date
        })
      },
      reprocessDoc () {
        this.loadingReprocessDoc = true
        this.$store.dispatch('documents/GET', {
          resource: `rec-docs/${this.$route.params.id}/reprocess`
        })
        .then(() => {
          // this.$dialog.message.info('El documento fue reprocesado con éxito.')
          this.disabledUpdate = true
          // setTimeout(() => {
          //   this.retrieve()
          // }, 600)
        })
        .catch((error) => {
          this.$dialog.message.error(error.response.data.message)
        })
        .finally(() => {
          this.confirm = ''
          this.loadingReprocessDoc = false
          this.dialogReprocessDoc = false
        })
      },
      onScroll (e) {
        this.offsetTop = e.target?.scrollingElement?.scrollTop ?? 0
      },
      async handlePanel () {
        await this.$root.$emit('isClose', true)
        this.$router.replace({ name: 'DocumentsList'})
      },
      updateSwap () {
        this.loadingSwap = true
        this.$store.dispatch('documents/UPDATE', {
          resource: `rec-docs/${this.$route.params.id}/rec-status`,
          detail: this.$route.params.id,
          payload: {
            rec_status: this.dialogSwapType !== 'reclaim' ? 'APR' : 'REJ',
            rec_status_info: this.swapResponse.rec_status_info
          }
        })
        .then((response) => {
          // this.swap = response.data
          this.$dialog.message.info(`El documento ha sido ${this.dialogSwapType !== 'reclaim' ? 'aceptado' : 'rechazado'} con éxito`)
          this.retrieve()
        })
        .catch((error) => {
          this.$dialog.message.error(this.parseErrors(error.response.data))
        })
        .finally(() => {
          this.loadingSwap = false
          this.swapResponse.rec_status_info = ''
          this.dialogSwap = false
        })
      },
      retrieve () {
        this.$store.dispatch('purchases/RETRIEVE', {
          resource: 'rec-docs',
          id: this.$route.params.id
        })
        .then((response) => {
          this.references = response.data.references
        })
      }
    }
  }
</script>
<style>
.v-list--three-line .v-list-item .v-list-item__subtitle, .v-list-item--three-line .v-list-item__subtitle {
  -webkit-line-clamp: 2 !important;
}
</style>